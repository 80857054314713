import React from 'react'
import Topbar from '../Components/Topbar'
import AboutusSmall from '../Components/AboutusSmall'
import Footer from '../Components/Footer'
import GalleryComponent from '../Components/GalleryComponent'
import HomeHeader from '../Components/HomeHeader'
import ServiceContainerMainPageComponent from '../Components/ServiceContainerMainPageComponent' 
import "animate.css/animate.compat.css"
import ScrollAnimation from 'react-animate-on-scroll'
import FloatinIcon from '../Components/FloatinIcon'
import HomeServiceContainerComponent from '../Components/HomeServiceContainerComponent'
import visionImage from '../images/visionicon.png'
import missionImage from '../images/mission.png'
import { Button, TextField } from '@mui/material'
import CommonHeader from '../Components/CommonHeader' 
import { IoLocation, IoCallSharp } from 'react-icons/io5'
import { AiFillClockCircle } from 'react-icons/ai'


function Home() {

    const visMisImages = {
        height: '200px'
    }

    return (
        <div>
            <HomeHeader />
            <div>
                <Topbar />
            </div>
            {/* <PageCarousel /> */}
            <div className='divider'></div>
            {/* <ServiceComponentSmall /> */}
            
            <div className='divider'></div>

            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div>
                    <div className='container'>
                        <AboutusSmall />
                    </div>
                </div>
            </ScrollAnimation>
            <div className='divider'></div>
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                <div className='container visionMissionContainer'>
                    <div className='text-center'>
                        <img src={visionImage} alt='' style={visMisImages} />
                        <div>Vision</div>
                        <p>Growing from disability to difference is a transformative journey that encompasses both personal and societal change. It is a path where meaningful coincidences play a significant role in shaping perspectives and fostering inclusivity. These synchronistic encounters connect individuals, inspiring empathy, understanding, and appreciation for diverse abilities. Through these encounters, barriers are shattered, prejudices are challenged, and a profound sense of unity emerges. Together, we forge a world that celebrates the beauty of differences, paving the way for a more inclusive and compassionate society.</p>
                    </div>
                    <div className='text-center'>
                        <img src={missionImage} alt='' style={visMisImages} />
                        <div>Mission</div>
                        <p>Creating Space for Energized Communities, also known as Living Free, is a transformative initiative dedicated to empowering mentally and physically challenged individuals through a culture of caring and sharing. By fostering an inclusive environment, we enable these individuals to thrive, breaking down barriers and nurturing their unique abilities. Through collaborative efforts and compassionate support, we establish safe spaces where everyone feels valued, heard, and respected. Together, we build a vibrant community that ignites energy, ignites passion, and celebrates the indomitable human spirit.</p>
                    </div>
                </div>
            </ScrollAnimation>
            <div></div>
            {/* <div className='ourServiceSectionClass'>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <div style={{ color: 'white' }}>
                        <div className='container p-5'>
                            <ServiceContainerMainPageComponent />
                        </div>
                    </div>
                </ScrollAnimation>
            </div> */}
            <div className='divider'></div>
            <div className='divider'></div>
            {/* <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div >
                    <div className='container'>
                        <BookAConsultation />
                    </div>
                </div>
            </ScrollAnimation> */}
            <div className='divider'></div>
            <div className='divider'></div>
            {/* <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div style={{ background: 'linear-gradient(360deg, #1a263f, transparent)', color: 'white' }} className='p-5'>
                    <div className='container'>
                        <GalleryComponent />
                    </div>
                </div>
            </ScrollAnimation> */}
            {/* <ChallengeDisabilityServices /> */}
            {/* <div className='divider'></div> */}

            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
            <div className='container'>
                <strong>
                    <h3 className='text-center'>Our Services</h3>
                </strong>
                <div className='divider'></div>
                <HomeServiceContainerComponent />
            </div>
            </ScrollAnimation>
            <div className='divider'></div>
            <div className='divider'></div>

            <div className='container p-4'>
                <div className='contactContainerCard w-100'>
                    <div className='d-flex flex-column w-100 gap-4 mt-0 mb-4'>
                        <div className='contact-cards'>
                            <div ><IoLocation style={{ fontSize: '30px', color: '#0065bb' }} /></div>
                            <div>
                                <strong>LOCATION</strong>
                                <p />
                                <p>Across NSW and Tasmania</p>
                            </div>
                        </div>
                        <div className='contact-cards'>
                            <div ><AiFillClockCircle style={{ fontSize: '30px', color: '#0065bb' }} /></div>
                            <div>
                                <strong>Opening Hours</strong>
                                <p />
                                <div>WEEKDAYS : 9:00 AM - 5:00 PM</div>
                                <div>WEEKENDS : Closed</div>
                            </div>
                        </div>
                        {/* <div className='contact-cards'>
                            <div ><IoCallSharp style={{ fontSize: '30px', color: '#0065bb' }} /></div>
                            <div>
                                <strong>CONTACT</strong>
                                <p />
                                <div>Address : #123, Sample Road, Sample Area, Location , 1123245</div>
                                <div>Phone : +61-403-953-209, +61-403-870-358</div>
                                <div>Email : info@navacare.com.au</div>
                            </div>
                        </div> */}
                    </div>
                    <div className='w-100'>
                        <form className='d-flex flex-column gap-4'>
                            <TextField
                                size='small'
                                label="Contact No"
                                required />
                            <TextField
                                size='small'
                                type='email'
                                label="Email"
                                required />
                            <TextField
                                multiline
                                rows={6}
                            />
                            <Button
                                size='large'
                                type='submit'
                                variant='contained'
                                required>Submit</Button>
                        </form >
                    </div>
                </div>
            </div>
            
            <div className='divider'></div>
            <Footer />
        </div>
    )
}

export default Home