import React from 'react'
import CommonHeader from '../Components/CommonHeader'
import Footer from '../Components/Footer'

function NDIS() {
  return (
    <div>
      <CommonHeader />
      <div className='services-page-header-container'>
        <div className='services-page-header-inner-container'>
          <h1 className='text-center'>National Disability Insurance Scheme (NDIS)</h1>
          <div className='dummy-line mb-4 w-100' />
          <h4 className='text-center'>Connecting you is our priority - Reach out to us today!</h4>
        </div>
      </div>
      <div className='container p-4'>
        <p>
          The National Disability Insurance Scheme (NDIS) is an essential initiative that has transformed the lives of individuals with disabilities in Australia. Launched in 2013, the NDIS is a comprehensive and person-centered approach to disability support, aiming to provide Australians with disabilities the necessary tools and resources to lead fulfilling and independent lives.
        </p><p>
        </p><p>
          As an NDIS approved service, we are proud to be part of the National Disability Insurance Scheme and to play a crucial role in supporting individuals with disabilities. Being an NDIS approved service means that we have met the rigorous standards set by the scheme, demonstrating our commitment to delivering high-quality and person-centered care to our participants.
        </p><p>
          Our primary goal is to empower individuals with disabilities to live their lives to the fullest. We work closely with our participants to understand their unique needs, goals, and aspirations. Through personalized support plans, we strive to provide the services and resources necessary to enhance their independence, well-being, and overall quality of life.
        </p><p>
          As an approved service provider, we offer a wide range of supports and services tailored to meet the diverse needs of our participants. This can include assistance with daily living activities, community participation, employment support, social inclusion, therapeutic interventions, and much more. We continuously adapt our services to align with the evolving needs and preferences of our participants, ensuring that they receive the support that best suits them.
        </p><p>
          Being part of the NDIS also means that we uphold the principles of choice and control. We believe in the importance of involving participants in decision-making processes and respecting their autonomy. Our dedicated team of professionals works collaboratively with participants and their families to ensure that their voices are heard, their choices are respected, and their goals are pursued.
        </p><p>
          As an NDIS approved service, we understand the significance of ongoing training and professional development. We invest in our staff to ensure that they possess the necessary skills, knowledge, and expertise to provide the best possible support to our participants. Our team members are passionate about what they do, and they are committed to making a positive difference in the lives of those we serve.
        </p><p>
          In summary, being an NDIS approved service is a privilege and a responsibility that we take seriously. We are dedicated to upholding the values and principles of the NDIS and to continuously improving the support we provide. Through our person-centered approach, comprehensive range of services, and commitment to choice and control, we strive to make a meaningful impact in the lives of individuals with disabilities and their families.
        </p>
      </div>
      <Footer/>
    </div>
  )
}

export default NDIS