import React from 'react'
import destination from '../images/destination.png'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'

import dailyLifeStyle from '../images/creative-thinking.png'
import routene from '../images/routine.png'
import clipboardImage from '../images/clipboard.png'
import meetingImage from '../images/meeting.png'
import tasks from '../images/task.png'
import partners from '../images/partners.png'

function HomeServiceContainerComponent() {

    const leftBorder = {
        borderLeft: '3px solid black',
        paddingLeft: '10px',
        marginLeft: '10px'
    }

    const serviceIconStyle = {
        width: '60px'
    }

    return (
        <div className='homeServiceContainer'>
            <div className='d-flex homeservicesecondcontainer'>
                <div>
                    <img src={destination} alt='' style={serviceIconStyle} />
                </div>
                <div className='d-flex flex-column' style={leftBorder}>
                    <strong>Assist Travel Transport (0108)</strong>
                    <p className='serviceBoxParagraphTextLarge'>
                        Travel Transport is an essential service offered under the National Disability Insurance Scheme (NDIS), designed to provide individuals with disabilities convenient and accessible means of transportation. We assist the service to enhance the mobility and independence of NDIS participants, ensuring they can access vital appointments, engage in social activities, and fulfill their daily needs with ease. Assist Travel Transport offers a reliable and inclusive transportation network, accommodating the unique requirements of each individual, and fostering a safe and comfortable journey for all. By facilitating seamless travel experiences, this service plays a vital role in empowering people with disabilities to actively participate in their communities and lead fulfilling lives.
                    </p>
                    <Link to={"/Assist_Travel_Transport"} ><Button variant='contained' color="success">Read More...</Button></Link>
                </div>
            </div>

            <div className='d-flex homeservicesecondcontainer'>
                <div>
                    <img src={dailyLifeStyle} alt='' style={serviceIconStyle} />
                </div>
                <div className='d-flex flex-column' style={leftBorder}>
                    <strong>Daily Living Life Skills (0117)</strong>
                    <p className='serviceBoxParagraphTextLarge'>
                        One of the primary goals of the Nava Care was to enable individuals who need reasonable and essential assistance to actively engage in their everyday activities and, consequently, promote their involvement in the wider community. An avenue through which this objective is achieved is Assistance with Daily Living, which offers support for routine personal tasks and includes supervision as needed. For instance, if you have a passion for tennis, attending craft or cooking lessons, or even singing, there is no reason to relinquish these enjoyable aspects of life.
                    </p>
                    <Link to={"/Daily_Living_Life_Skills"} ><Button variant='contained' color="success">Read More...</Button></Link>
                </div>
            </div>

            <div className='d-flex homeservicesecondcontainer'>
                <div>
                    <img src={clipboardImage} alt='' style={serviceIconStyle} />
                </div>
                <div className='d-flex flex-column' style={leftBorder}>
                    <strong>Daily Personal Activities (0107)</strong>
                    <p className='serviceBoxParagraphTextLarge'>
                        One of the primary goals of the Nava Care was to enable individuals who need reasonable and essential assistance to actively engage in their everyday activities and, consequently, promote their involvement in the wider community. An avenue through which this objective is achieved is Assistance with Daily Living, which offers support for routine personal tasks and includes supervision as needed. For instance, if you have a passion for tennis, attending craft or cooking lessons, or even singing, there is no reason to relinquish these enjoyable aspects of life.
                    </p>
                    <Link to={"/Daily_Personal_Activities"} ><Button variant='contained' color="success">Read More...</Button></Link>
                </div>
            </div>




            <div className='d-flex homeservicesecondcontainer'>
                <div>
                    <img src={routene} alt='' style={serviceIconStyle} />
                </div>
                <div className='d-flex flex-column' style={leftBorder}>
                    <strong>Daily Tasks Shared Living (0115)</strong>
                    <p className='serviceBoxParagraphTextLarge'>
                        One of the primary goals of the Nava Care was to enable individuals who need reasonable and essential assistance to actively engage in their everyday activities and, consequently, promote their involvement in the wider community. An avenue through which this objective is achieved is Assistance with Daily Living, which offers support for routine personal tasks and includes supervision as needed. For instance, if you have a passion for tennis, attending craft or cooking lessons, or even singing, there is no reason to relinquish these enjoyable aspects of life.
                    </p>
                    <Link to={"/Daily_Tasks_Shared_Living"} ><Button variant='contained' color="success">Read More...</Button></Link>
                </div>
            </div>


            <div className='d-flex homeservicesecondcontainer'>
                <div>
                    <img src={meetingImage} alt='' style={serviceIconStyle} />
                </div>
                <div className='d-flex flex-column' style={leftBorder}>
                    <strong>Group Centre Based Activities (0136)</strong>
                    <p className='serviceBoxParagraphTextLarge'>
                        Group Centre Based Activities is a valuable component of the Nava Care that aims to foster social interaction, skill development, and community engagement for individuals with disabilities. This service provides a supportive and inclusive environment where participants can come together and participate in a variety of structured group activities. These activities can range from educational workshops, recreational pursuits, arts and crafts, physical exercises, and cultural events, among others. Group Centre Based Activities offer opportunities for individuals to learn new skills, build friendships, and enhance their overall well-being. The Nava Care recognizes the importance of social connection and personal development, and through these activities, participants can actively participate in the community, expand their horizons, and enjoy a sense of belonging. By promoting inclusivity and providing a platform for shared experiences, Group Centre Based Activities under the Nava Care contribute to the empowerment and enrichment of the lives of individuals with disabilities.
                    </p>
                    <Link to={"/Group_Centre_Based_Activities"} ><Button variant='contained' color="success">Read More...</Button></Link>
                </div>
            </div>

            <div className='d-flex homeservicesecondcontainer'>
                <div>
                    <img src={tasks} alt='' style={serviceIconStyle} />
                </div>
                <div className='d-flex flex-column' style={leftBorder}>
                    <strong>Household Tasks (0120)</strong>
                    <p className='serviceBoxParagraphTextLarge'>
                        Household Tasks is a valuable support category offered under the Nava Care, aimed at assisting individuals with disabilities in managing various household activities. This support enables participants to maintain a clean, safe, and functional living environment while promoting their independence and well-being. Household Tasks encompasses a wide range of essential duties, including cleaning, laundry, meal preparation, and basic home maintenance. Skilled and trained support workers are available to lend a helping hand, ensuring that participants can navigate their household tasks efficiently. By receiving assistance with these daily responsibilities, individuals with disabilities can focus on other aspects of their lives, participate in meaningful activities, and enjoy a comfortable and organized home environment. The Nava Care acknowledges the significance of Household Tasks in promoting a sense of dignity, self-sufficiency, and improved quality of life for participants.
                    </p>
                    <Link to={"/Household_Tasks"} ><Button variant='contained' color="success">Read More...</Button></Link>
                </div>
            </div>

            <div className='d-flex homeservicesecondcontainer'>
                <div>
                    <img src={partners} alt='' style={serviceIconStyle} />
                </div>
                <div className='d-flex flex-column' style={leftBorder}>
                    <strong>Participate Community (0125)</strong>
                    <p className='serviceBoxParagraphTextLarge'>
                        The Participate Community feature of the Disability Service Center is a remarkable platform that fosters inclusivity, support, and empowerment for individuals with disabilities. It serves as a vibrant hub where members can connect, engage, and share their experiences, challenges, and successes. Through this feature, individuals from diverse backgrounds and abilities come together, forming a tight-knit community that promotes understanding and empathy. The Participate Community provides a safe and inclusive space for discussions, resource sharing, and collaboration, allowing members to seek guidance, advice, and encouragement from peers who have faced similar circumstances. It serves as a valuable support network, enabling individuals to overcome obstacles, discover new opportunities, and build lifelong friendships. By harnessing the power of technology and the strength of community, the Disability Service Center's Participate Community empowers individuals with disabilities to thrive, break down barriers, and create positive change in their lives and the world around them.
                    </p>
                    <Link to={"/Participate_Community"} ><Button variant='contained' color="success">Read More...</Button></Link>
                </div>
            </div>


        </div>
    )
}

export default HomeServiceContainerComponent