import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from '../images/logo white.png'
import { HiMail } from 'react-icons/hi'
import { AiFillPhone } from 'react-icons/ai'
import { GiHamburgerMenu } from 'react-icons/gi'
import { Menu, MenuItem } from '@mui/material'

function CustomTopbar() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className='topbar-custom'>
            <div className='container mb-2 d-flex justify-content-between'>
                <div className='topbar-contact-content-container'>
                    <small style={{ fontSize: '12px' }}><AiFillPhone /> +61-403-953-209, &nbsp;</small>
                    <small style={{ fontSize: '12px' }}><AiFillPhone /> +61-403-870-358</small>
                </div>
                <div className='topbar-contact-content-container'>
                    <small style={{ fontSize: '12px' }}><HiMail /> enquiry@navacare.com.au, &nbsp;</small>
                    <small style={{ fontSize: '12px' }}><HiMail /> info@navacare.com.au</small>
                </div>
            </div>
            <div className='container d-flex justify-content-between align-items-center'>
                <div>
                    <img src={logo} alt='' className='topbar-logo' />
                </div>
                <div className='d-flex align-items-center'>
                <div className='mobile-menu-icon'>
                    <GiHamburgerMenu style={{ fontSize: "25px" }} onClick={handleClick} />
                </div>
                    <ul className='home-menu'>
                        <NavLink to='/'><li>Home</li></NavLink>
                        {/* <NavLink to={'/NDIS'}><li>NDIS</li></NavLink> */}
                        {/* <NavLink to='/Services' ><li>Services</li></NavLink> */}
                        <NavLink to='/Career' ><li>Career</li></NavLink>
                        <NavLink to='/About_Us'><li>About Us</li></NavLink>
                        <NavLink to='/Contact_us'><li>Contact Us</li></NavLink>
                    </ul>
                </div>
            </div>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose}><Link to={"/"}>Home</Link></MenuItem>
                {/* <MenuItem onClick={handleClose}><Link to={"/"}>NDIS</Link></MenuItem> */}
                {/* <MenuItem onClick={handleClose}><Link to={"/Services"}>Services</Link></MenuItem> */}
                <MenuItem onClick={handleClose}><Link to={"/Career"}>Career</Link></MenuItem>
                <MenuItem onClick={handleClose}><Link to={"/About_Us"}>About Us</Link></MenuItem>
                <MenuItem onClick={handleClose}><Link to={"/Contact_us"}>Contact Us</Link></MenuItem>
            </Menu>
        </div>
    )
}

export default CustomTopbar
