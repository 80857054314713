import React from 'react'
import CommonHeader from '../Components/CommonHeader'
import Footer from '../Components/Footer'
import image from '../images/children-with-down-syndrome-drawing-together.jpg'
import ScrollAnimation from 'react-animate-on-scroll'

function DailyTasks() {
    return (
        <div>
            <CommonHeader />
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className='services-page-header-container'>
                    <div className='services-page-header-inner-container'>
                        <h1 className='text-center'>Daily Tasks Shared Living (0115)</h1>
                        <div className='dummy-line mb-4 w-100' />
                        {/* <h4 className='text-center'>Connecting you is our priority - Reach out to us today!</h4> */}
                    </div>
                </div>
            </ScrollAnimation>
            <div className='divider'></div>
            {/* <h3 className='text-center'>  Daily Tasks Shared Living (0115)</h3> */}
            <div className='container'>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <div className='who-are-we-container p-4 gap-4'>
                        <div>
                            <img src={image} className='serviceInnerImage' alt='' />
                        </div>
                        <div>
                            <p>
                                Daily Tasks Shared Living is an integral component of the Nava Care, designed to support individuals with disabilities who are living in shared accommodation arrangements. This Nava Care service aims to ensure that participants can effectively manage and navigate their daily routines within a shared living environment. It encompasses a wide range of tasks, including but not limited to meal planning and preparation, household chores, cleaning, laundry, grocery shopping, and maintaining a safe and comfortable living space. By providing assistance and support with these daily tasks, Nava Care promotes a sense of independence, cooperation, and community inclusion among participants. Moreover, it allows individuals to focus on their personal goals and aspirations, as they have access to reliable and tailored support for the practical aspects of daily living. Through Daily Tasks Shared Living, the Nava Care strives to create a nurturing and inclusive environment where individuals with disabilities can thrive and lead fulfilling lives alongside their housemates.
                            </p>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
            <Footer />
        </div>
    )
}

export default DailyTasks