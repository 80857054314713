import React from 'react'
import silImage from '../images/full-shot-people-with-map.jpg'
import sdaimg from '../images/children-with-down-syndrome-drawing-together.jpg'
import adlimg from '../images/adl-img.jpg'
import cmimg from '../images/side-view-woman-helping-girl-with-down-syndrome-paint.jpg'
import rcimg from '../images/rc-img.jpg'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// import BookAConsultation from './BookAConsultation'

function ServicePageServiceGrid() {
    return (
        <>
            <div className='services-page-header-container'>
                <div className='services-page-header-inner-container'>
                    <h1 className='text-center'>SERVICES</h1>
                    <div className='dummy-line mb-4 w-100' />
                    {/* <h4 className='text-center'>Provided by Varified NDIS Provider</h4> */}
                </div>
            </div>
            <div className='divider' />
            <div className='container text-center' >
                {/* The NDIS members will receive comprehensive and all-encompassing care from Nava Care. Check out the services we offer and get in touch with us to speak with a member of our staff. */}
            </div>
            <div className='divider' />
            <div className='pt-5 pb-5' style={{ background: '#f3f9fc' }}>
                <div className='container serviceContainerGrid'>
                    <Link to={"/Assist_Travel_Transport"} className='serviceGridBox'>
                        <img src={silImage} alt='' className='w-100' />
                        <div className='text-center m-2 '>
                            <strong>
                                Assist Travel Transport (0108)
                            </strong>
                        </div>
                        <p className="serviceBoxParagraphText p-2">
                            Travel Transport is an essential service offered under the National Disability Insurance Scheme (NDIS), designed to provide individuals with disabilities convenient and accessible means of transportation. We assist the service to enhance the mobility and independence of NDIS participants, ensuring they can access vital appointments, engage in social activities, and fulfill their daily needs with ease. Assist Travel Transport offers a reliable and inclusive transportation network, accommodating the unique requirements of each individual, and fostering a safe and comfortable journey for all. By facilitating seamless travel experiences, this service plays a vital role in empowering people with disabilities to actively participate in their communities and lead fulfilling lives.
                        </p>
                        <div className="text-center"><Button>Read More &gt;&gt;</Button></div>
                    </Link>
                    <Link to={'/Daily_Living_Life_Skills'} className='serviceGridBox'>
                        <img src={adlimg} alt='' className='w-100' />
                        <div className='text-center m-2'>
                            <strong>
                                Daily Living Life Skills (0117)
                            </strong>
                        </div>
                        <p className="serviceBoxParagraphText" >
                            One of the primary goals of the Nava Care was to enable individuals who need reasonable and essential assistance to actively engage in their everyday activities and, consequently, promote their involvement in the wider community. An avenue through which this objective is achieved is Assistance with Daily Living, which offers support for routine personal tasks and includes supervision as needed. For instance, if you have a passion for tennis, attending craft or cooking lessons, or even singing, there is no reason to relinquish these enjoyable aspects of life.
                        </p>
                        <div className="text-center"><Button>Read More &gt;&gt;</Button></div>
                    </Link>
                    <Link to={"/Daily_Personal_Activities"} className='serviceGridBox'>
                        <img src={cmimg} alt='' className='w-100' />
                        <div className='text-center m-2'>
                            <strong>
                                Daily Personal Activities (0107)
                            </strong>
                        </div>
                        <p className="serviceBoxParagraphText">
                            The Nava Care give importance of daily personal activities in promoting independence and well-being for individuals with disabilities. We provides support through Assistance with Daily Living, ensuring that participants can carry out their personal tasks and routines with confidence and assistance when needed. These activities encompass a wide range of essential aspects, such as personal hygiene, grooming, dressing, meal preparation, eating, mobility, and medication management. By offering personalized support, the NDIS empowers individuals to maintain their dignity and autonomy while engaging in their daily routines. This assistance not only enhances their quality of life but also fosters a sense of self-worth and inclusion within the community. Through the provision of Nava care daily personal activities, individuals with disabilities are empowered to lead fulfilling lives and actively participate in society.
                        </p>
                        <div className="text-center"><Button>Read More &gt;&gt;</Button></div>
                    </Link>
                    <Link to={"/Daily_Tasks_Shared_Living"} className='serviceGridBox'>
                        <img src={rcimg} alt='' className='w-100' />
                        <div className='text-center mt-2'>
                            <strong>
                                Daily Tasks Shared Living (0115)
                            </strong>
                        </div>
                        <p className="serviceBoxParagraphText">
                            Daily Tasks Shared Living is an integral component of the Nava Care, designed to support individuals with disabilities who are living in shared accommodation arrangements. This Nava Care service aims to ensure that participants can effectively manage and navigate their daily routines within a shared living environment. It encompasses a wide range of tasks, including but not limited to meal planning and preparation, household chores, cleaning, laundry, grocery shopping, and maintaining a safe and comfortable living space. By providing assistance and support with these daily tasks, Nava Care promotes a sense of independence, cooperation, and community inclusion among participants. Moreover, it allows individuals to focus on their personal goals and aspirations, as they have access to reliable and tailored support for the practical aspects of daily living. Through Daily Tasks Shared Living, the Nava Care strives to create a nurturing and inclusive environment where individuals with disabilities can thrive and lead fulfilling lives alongside their housemates.
                        </p>
                        <div className="text-center"><Button>Read More &gt;&gt;</Button></div>
                    </Link>
                    <Link to={"/Group_Centre_Based_Activities"} className='serviceGridBox'>
                        <img src={sdaimg} alt='' className='w-100' />
                        <div className='text-center mt-2'>
                            <strong>
                                Group Centre Based Activities (0136)
                            </strong>
                        </div>
                        <p className="serviceBoxParagraphText">
                            Group Centre Based Activities is a valuable component of the Nava Care that aims to foster social interaction, skill development, and community engagement for individuals with disabilities. This service provides a supportive and inclusive environment where participants can come together and participate in a variety of structured group activities. These activities can range from educational workshops, recreational pursuits, arts and crafts, physical exercises, and cultural events, among others. Group Centre Based Activities offer opportunities for individuals to learn new skills, build friendships, and enhance their overall well-being. The Nava Care recognizes the importance of social connection and personal development, and through these activities, participants can actively participate in the community, expand their horizons, and enjoy a sense of belonging. By promoting inclusivity and providing a platform for shared experiences, Group Centre Based Activities under the Nava Care contribute to the empowerment and enrichment of the lives of individuals with disabilities.
                        </p>
                        <div className="text-center"><Button>Read More &gt;&gt;</Button></div>
                    </Link>
                    <Link to={"/Household_Tasks"} className='serviceGridBox'>
                        <img src={sdaimg} alt='' className='w-100' />
                        <div className='text-center mt-2'>
                            <strong>
                                Household Tasks (0120)
                            </strong>
                        </div>
                        <p className="serviceBoxParagraphText">
                        Household Tasks   is a valuable support category offered under the Nava Care, aimed at assisting individuals with disabilities in managing various household activities. This support enables participants to maintain a clean, safe, and functional living environment while promoting their independence and well-being. Household Tasks  encompasses a wide range of essential duties, including cleaning, laundry, meal preparation, and basic home maintenance. Skilled and trained support workers are available to lend a helping hand, ensuring that participants can navigate their household tasks efficiently. By receiving assistance with these daily responsibilities, individuals with disabilities can focus on other aspects of their lives, participate in meaningful activities, and enjoy a comfortable and organized home environment. The Nava Care acknowledges the significance of Household Tasks  in promoting a sense of dignity, self-sufficiency, and improved quality of life for participants.
                        </p>

                        <div className="text-center"><Button>Read More &gt;&gt;</Button></div>
                    </Link>
                    <Link to={"/Participate_Community"} className='serviceGridBox'>
                        <img src={sdaimg} alt='' className='w-100' />
                        <div className='text-center mt-2'>
                            <strong>
                                Participate Community (0125)
                            </strong>
                        </div>
                        <p className="serviceBoxParagraphText">
                            Group Centre Based Activities is a valuable component of the Nava Care that aims to foster social interaction, skill development, and community engagement for individuals with disabilities. This service provides a supportive and inclusive environment where participants can come together and participate in a variety of structured group activities. These activities can range from educational workshops, recreational pursuits, arts and crafts, physical exercises, and cultural events, among others. Group Centre Based Activities offer opportunities for individuals to learn new skills, build friendships, and enhance their overall well-being. The Nava Care recognizes the importance of social connection and personal development, and through these activities, participants can actively participate in the community, expand their horizons, and enjoy a sense of belonging. By promoting inclusivity and providing a platform for shared experiences, Group Centre Based Activities under the Nava Care contribute to the empowerment and enrichment of the lives of individuals with disabilities.
                        </p>
                        <div className="text-center"><Button>Read More &gt;&gt;</Button></div>
                    </Link>
                </div>
            </div>
            <div className='divider'></div>
            {/* <BookAConsultation /> */}
            <div className='divider'></div>
            <div className='divider'></div>
        </>
    )
}

export default ServicePageServiceGrid