import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom'
import Home from './Pages/Home';
import Services from './Pages/Services';
import Sil from './Pages/Sil';
import AssistanceWithDailyLiving from './Pages/AssistanceWithDailyLiving';
import CommunityAccess from './Pages/CommunityAccess';
import ShortTermAccomodation from './Pages/ShortTermAccomodation';
import DisabilityAcomodation from './Pages/DisabilityAcomodation';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';
import FloatinIcon from './Components/FloatinIcon';
import NDIS from './Pages/NDIS';
import HouseholdTasks from './Pages/HouseholdTasks';
import GroupCentre from './Pages/GroupCentre';
import DailyTasks from './Pages/DailyTasks';
import DailyPersonal from './Pages/DailyPersonal';
import Career from './Pages/Career';
import { useEffect, useState } from 'react';
import PageLoader from './Components/PageLoader';

function App() {

  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);


  return (
    <>
      {isLoading ?
        <>
          <PageLoader />
        </>
        :
        <>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/Services' element={<Services />} />
            <Route path='/Assist_Travel_Transport' element={<Sil />} />
            <Route path='/NDIS' element={<NDIS />} />
            <Route path='/Daily_Living_Life_Skills' element={<AssistanceWithDailyLiving />} />
            <Route path='/CommunityAccess' element={<CommunityAccess />} />
            <Route path='/ShortTermAccomodation' element={<ShortTermAccomodation />} />
            <Route path='/Participate_Community' element={<DisabilityAcomodation />} />
            <Route path='/Household_Tasks' element={<HouseholdTasks />} />
            <Route path='/Group_Centre_Based_Activities' element={<GroupCentre />} />
            <Route path='/Daily_Tasks_Shared_Living' element={<DailyTasks />} />
            <Route path='/Daily_Personal_Activities' element={<DailyPersonal />} />
            <Route path='/About_Us' element={<AboutUs />} />
            <Route path='/Career' element={<Career />} />
            <Route path='/Contact_us' element={<ContactUs />} />
          </Routes>
          <FloatinIcon />
        </>
      }

    </>
  );
}

export default App;
