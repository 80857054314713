import React from 'react'
import Footer from '../Components/Footer'
import image from '../images/sil-img.jpg'
import CommonHeader from '../Components/CommonHeader'
import ScrollAnimation from 'react-animate-on-scroll'

function Sil() {
    return (
        <div>
            <CommonHeader />
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className='services-page-header-container'>
                    <div className='services-page-header-inner-container'>
                        <h1 className='text-center'>Assist Travel Transport (0108)</h1>
                        <div className='dummy-line mb-4 w-100' />
                        {/* <h4 className='text-center'>Connecting you is our priority - Reach out to us today!</h4> */}
                    </div>
                </div>
            </ScrollAnimation>
            <div className='divider'></div>
            <div className='container'>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <div className='who-are-we-container p-4 gap-4'>
                        <div>
                            <img src={image} className='serviceInnerImage' alt='' />
                        </div>
                        <div>
                            <p>
                                Travel Transport is an essential service offered under the National Disability Insurance Scheme (NDIS), designed to provide individuals with disabilities convenient and accessible means of transportation. We assist the service to enhance the mobility and independence of NDIS participants, ensuring they can access vital appointments, engage in social activities, and fulfill their daily needs with ease. Assist Travel Transport offers a reliable and inclusive transportation network, accommodating the unique requirements of each individual, and fostering a safe and comfortable journey for all. By facilitating seamless travel experiences, this service plays a vital role in empowering people with disabilities to actively participate in their communities and lead fulfilling lives.
                            </p>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
            <Footer />
        </div>
    )
}

export default Sil