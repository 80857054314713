import React from 'react'
import CommonHeader from '../Components/CommonHeader'
import Footer from '../Components/Footer'
import image from '../images/children-with-down-syndrome-drawing-together.jpg'
import ScrollAnimation from 'react-animate-on-scroll'

function DisabilityAcomodation() {
    return (
        <div>
            <CommonHeader />
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className='services-page-header-container'>
                    <div className='services-page-header-inner-container'>
                        <h1 className='text-center'>Participate Community (0125)</h1>
                        <div className='dummy-line mb-4 w-100' />
                        {/* <h4 className='text-center'>Connecting you is our priority - Reach out to us today!</h4> */}
                    </div>
                </div>
            </ScrollAnimation>
            <div className='divider'></div>
            <div className='divider'></div>
            {/* <h3 className='text-center'> Participate Community (0125)</h3> */}
            <div className='container'>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <div className='who-are-we-container p-4 gap-4'>
                        <div>
                            <img src={image} className='serviceInnerImage' alt='' />
                        </div>
                        <div>
                            <p>
                                The Participate Community feature of the Disability Service Center is a remarkable platform that fosters inclusivity, support, and empowerment for individuals with disabilities. It serves as a vibrant hub where members can connect, engage, and share their experiences, challenges, and successes. Through this feature, individuals from diverse backgrounds and abilities come together, forming a tight-knit community that promotes understanding and empathy. The Participate Community provides a safe and inclusive space for discussions, resource sharing, and collaboration, allowing members to seek guidance, advice, and encouragement from peers who have faced similar circumstances. It serves as a valuable support network, enabling individuals to overcome obstacles, discover new opportunities, and build lifelong friendships. By harnessing the power of technology and the strength of community, the Disability Service Center's Participate Community empowers individuals with disabilities to thrive, break down barriers, and create positive change in their lives and the world around them.
                            </p>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
            <Footer />
        </div>
    )
}

export default DisabilityAcomodation