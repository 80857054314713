import React from 'react'
import CommonHeader from '../Components/CommonHeader'
import Footer from '../Components/Footer'
import image from '../images/children-with-down-syndrome-drawing-together.jpg'
import ScrollAnimation from 'react-animate-on-scroll'

function HouseholdTasks() {
    return (
        <div>
            <CommonHeader />
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className='services-page-header-container'>
                    <div className='services-page-header-inner-container'>
                        <h1 className='text-center'>Household Tasks (0120)</h1>
                        <div className='dummy-line mb-4 w-100' />
                        {/* <h4 className='text-center'>Connecting you is our priority - Reach out to us today!</h4> */}
                    </div>
                </div>
            </ScrollAnimation>
            <div className='divider'></div>
            <div className='divider'></div>
            {/* <h3 className='text-center'> Household Tasks (0120)</h3> */}
            <div className='container'>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <div className='who-are-we-container p-4 gap-4'>
                        <div>
                            <img src={image} className='serviceInnerImage' alt='' />
                        </div>
                        <div>
                            <p>
                                Household Tasks   is a valuable support category offered under the Nava Care, aimed at assisting individuals with disabilities in managing various household activities. This support enables participants to maintain a clean, safe, and functional living environment while promoting their independence and well-being. Household Tasks  encompasses a wide range of essential duties, including cleaning, laundry, meal preparation, and basic home maintenance. Skilled and trained support workers are available to lend a helping hand, ensuring that participants can navigate their household tasks efficiently. By receiving assistance with these daily responsibilities, individuals with disabilities can focus on other aspects of their lives, participate in meaningful activities, and enjoy a comfortable and organized home environment. The Nava Care acknowledges the significance of Household Tasks  in promoting a sense of dignity, self-sufficiency, and improved quality of life for participants.
                            </p>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
            <Footer />
        </div>
    )
}

export default HouseholdTasks