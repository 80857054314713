import React from 'react'
import CommonHeader from '../Components/CommonHeader'
import image from '../images/children-with-down-syndrome-drawing-together.jpg'
import Footer from '../Components/Footer'
import ScrollAnimation from 'react-animate-on-scroll'

function GroupCentre() {
    return (
        <div>
            <CommonHeader />
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className='services-page-header-container'>
                    <div className='services-page-header-inner-container'>
                        <h1 className='text-center'>Group Centre Based Activities (0136)</h1>
                        <div className='dummy-line mb-4 w-100' />
                        {/* <h4 className='text-center'>Connecting you is our priority - Reach out to us today!</h4> */}
                    </div>
                </div>
            </ScrollAnimation>
            <div className='divider'></div>
            {/* <h3 className='text-center'> Group Centre Based Activities (0136)</h3> */}
            <div className='container'>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <div className='who-are-we-container p-4 gap-4'>
                        <div>
                            <img src={image} className='serviceInnerImage' alt='' />
                        </div>
                        <div>
                            <p>
                                Group Centre Based Activities is a valuable component of the Nava Care that aims to foster social interaction, skill development, and community engagement for individuals with disabilities. This service provides a supportive and inclusive environment where participants can come together and participate in a variety of structured group activities. These activities can range from educational workshops, recreational pursuits, arts and crafts, physical exercises, and cultural events, among others. Group Centre Based Activities offer opportunities for individuals to learn new skills, build friendships, and enhance their overall well-being. The Nava Care recognizes the importance of social connection and personal development, and through these activities, participants can actively participate in the community, expand their horizons, and enjoy a sense of belonging. By promoting inclusivity and providing a platform for shared experiences, Group Centre Based Activities under the Nava Care contribute to the empowerment and enrichment of the lives of individuals with disabilities.
                            </p>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
            <Footer />
        </div>
    )
}

export default GroupCentre