import React from 'react'
import CommonHeader from '../Components/CommonHeader'
import Footer from '../Components/Footer'

function CommunityAccess() {
    return (
        <div>
            <CommonHeader />
            <div className='divider'></div>
            <div className='container'>
                <h3 className='text-center'>COMMUNITY ACCESS & ACTIVITIES</h3>
                <p>
                    Your life is meant to be lived to the fullest.
                </p>
                <p>
                    Social and recreational activities are an important part of life for many people.
                </p>
                <p>
                    While some may be able to engage in their communities independently, there are many other who require assistance to do so.
                </p>
                <p>
                    Under your NDIS plan, you can get involved in a wide range of community activities that will enhance your quality of life and instil a greater degree of independence and empowerment within you.
                </p>
                <p>
                    Ave Maria will help you to engage in and embrace of a wide range of activities that focus on community participation and independent living skills training as well as new skills development.
                </p>
            </div>

            <div className='container'>
                <strong> Examples of community activities include;</strong>
                <ul>
                    <li>
                        Developing positive health & wellbeing
                    </li>
                    <li>
                        Learning new living & social skills such as cooking, carpentry, knitting and many others
                    </li>
                    <li>
                        Visiting parks, clubs, cinemas, and a wide range of indoor and outdoor activities – where you can meet people and maybe even make new friends
                    </li>
                    <li>
                        Bush walking, swimming and even bowling
                    </li>
                    <li>
                        Participating in development groups where you can learn coping and life management skills
                    </li>
                </ul>

                <p>
                    At Ave Maria, we don’t believe in a one size fits all solution, rather, the activities and community interactions must be line with your own specific needs and goals – after all, we are all individuals.
                </p>
                <p>
                    We actively encourage you in making your own choices and developing your own level of independence.
                </p>

            </div>

            <div className='container'>
                And while we encourage community interactions by way of social interactions and skills development, Community Access & Activities also extends to day-to-day tasks that you may need to accomplish such as;

                <ul>
                    <li>
                        Grocery shopping
                    </li>
                    <li>
                        Attending medical appointments
                    </li>
                    <li>
                        Going to the post office or bank
                    </li>
                    <li>
                        Attending your gym sessions
                    </li>
                    <li>
                        Visiting family and friends
                    </li>
                    <li>
                        Pampering yourself – hairdressers or nail salons
                    </li>
                    <li>
                        Or even just going out to a café or restaurant
                    </li>
                </ul>


                <p>
                    You can rest assured, your safety, wellbeing and security is our number one consideration when helping you choose and design your community interaction plan – Ave Maria will be with you at each step through your journey.
                </p>
                <p>
                    Contact Ave Maria for your no obligation consultation regarding the best solution for your needs and goals.
                </p>





            </div>

            <Footer />
        </div>
    )
}

export default CommunityAccess