import React from 'react'
import CustomTopbar from './CustomTopbar'
 
function CommonHeader() {
    return (
        <div>
            <CustomTopbar />
           
        </div>
    )
}

export default CommonHeader