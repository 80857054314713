import { Fab } from '@mui/material'
import React from 'react'
import { MdArrowBackIosNew } from 'react-icons/md'


function FloatinIcon() {
    const navigateTop = () => {
        window.scrollTo({ top: 0 })
    }

    return (

        <div className="fab">
            <Fab sx={{
                transform: 'rotate(90deg)'
            }}
                color='info'
                size="medium" onClick={() => navigateTop()}>
                <MdArrowBackIosNew />
            </Fab>
        </div>
    )
}

export default FloatinIcon