import React from 'react'
import CommonHeader from '../Components/CommonHeader'
import { Button, TextField } from '@mui/material'
import { Form } from 'react-bootstrap'
import Footer from '../Components/Footer'
import ScrollAnimation from 'react-animate-on-scroll'
function Career() {




    return (
        <>
            <CommonHeader />
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className='services-page-header-container'>
                    <div className='services-page-header-inner-container'>
                        <h1 className='text-center'>Careers</h1>
                        <div className='dummy-line mb-4 w-100' />
                        {/* <h4 className='text-center'>Connecting you is our priority - Reach out to us today!</h4> */}
                    </div>
                </div>
            </ScrollAnimation>
            <div className='divider'></div>

            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className='container career-container'>
                    <div>Name</div>
                    <div className='d-flex gap-2'>
                        <Form.Control placeholder='First Name' />
                        <Form.Control placeholder='Last Name' />
                    </div>
                    <div>Contact No</div>
                    <div>
                        <Form.Control type='phone' />
                    </div>
                    <div>Email Id</div>
                    <div>
                        <Form.Control type='phone' />
                    </div>
                    <div>Qualificaton</div>
                    <div>
                        <Form.Select >
                            <option hidden>Select highest qualification</option>
                            <option>Doctoral Degree</option>
                            <option>Master’s Degree</option>
                            <option>Bachelor Degree</option>
                            <option>Diploma</option>
                            <option>Certificate IV</option>
                            <option>Cerificate III</option>
                        </Form.Select>
                    </div>
                    <div>Resume</div>
                    <div><Form.Control type='file' /></div>
                    <div>NDIS Worker Screening Check</div>
                    <div><Form.Control type='file' /></div>
                    <div>NDIS Worker Orientation Module Cerificate</div>
                    <div><Form.Control type='file' /></div>
                    <div>Proof of Vaccination</div>
                    <div><Form.Control type='file' /></div>
                    <div>Working with Vulnerable Children Check / Blue card</div>
                    <div><Form.Control type='file' /></div>
                    <div></div>
                    <div><Button variant='contained' color='success'>Save</Button></div>
                </div>
            </ScrollAnimation>
            <div className='divider'></div>
            <div className='divider'></div>
            <Footer />
        </>
    )
}

export default Career