import React from 'react'
import CommonHeader from '../Components/CommonHeader'
import Footer from '../Components/Footer'

function ShortTermAccomodation() {
    return (
        <div>
            <CommonHeader />
            <div className='divider'></div>
            <div className='container'>
                <h3 className='text-center'>RESPITE / SHORT TERM ACCOMMODATION</h3>

                <p>
                    If you are a carer for a person with a disability, then we truly understand the responsibility you have taken on.
                </p>
                <p>
                    However, regardless of the love, care, and attention you provide as the carer, there are times when you (the carer), may need to take some time out to recharge and refresh yourself, so you can continue doing the amazing and self-less job that you are doing.
                </p>
                <p>
                    Nava Care offers Respite Care to allow you, the carer, to have that time-out and recharge.
                </p>


                <h4>What is Respite / Short Term Accommodation</h4>
                <p>
                    Respite Care is when someone else takes care of the person that you care for, so you can take a break.
                </p>
                <p>
                    As a carer, it’s very important that you regularly take time out for yourself to rest and recharge so you can continue to provide the loving care and attention that you have been providing.
                </p>
                <p>
                    Respite care can be given by family, friends or by a Respite service like Nava Care – which can take place at home or in a residential care facility.
                </p>
                <p>
                    Respite Care is very flexible in that it can be;
                </p>
                <ul>
                    <li>
                        Short Term Accommodation
                    </li>
                    <li>
                        Medium Term Accommodation
                    </li>
                </ul>

                <h4>
                    Short Term Accommodation
                </h4>

                <p>
                    Short term accommodation, including respite, is an option when you have to live out of home for a short period of time.
                </p>
                <p>
                    Under NDIS funding, short term accommodation respite care is available for participants and their carers.
                </p>
                <p>
                    Other than having some time out, sometimes a short term stay can have other benefits, including;
                </p>

                <ul>
                    <li>
                        A chance to try out new things
                    </li>
                    <li>
                        A place to make new friends or even take up hobbies and develop new skills
                    </li>
                    <li>
                        And of course, your quality of life improves simply because your carer has an opportunity to recharge
                    </li>
                </ul>


                <h4>
                    Medium Term Accommodation
                </h4>

                <p>
                    Sometimes it is necessary to move out of your current living situation for an extended period of time, for example; your current living premises require modifications or your are waiting for your confirmed Specialty Disability Accommodation to be confirmed.
                </p>
                <p>
                    The time frame for medium term accommodation can be up to 90 days (3 months).
                </p>
                <p>
                    Note though, funding for medium care accommodation only includes the accommodation cost of where you stay, it does not cover day-day living costs such as food, electricity, internet and like.
                </p>
                <p>
                    Please check with your NDIS provider for more information and NDIS will and will not cover under medium term accommodation.
                </p>


                <h4>
                    Respite / Short Term Accommodation Concerns
                </h4>
                <p>
                    As a carer, you have been providing the best and most loving attention, and naturally, your biggest concern with Respite Care might be, “will they do as good a job as I’ve been doing?”
                </p>
                <p>
                    Nava Care, we understand this concern and take it seriously.
                </p>
                <p>
                    We will ensure guests are safe, supported and that their goals and routines are met – just as you (the carer), have been doing.
                </p>
                <p>
                    You stay will be planned based on your individual needs and goals.
                </p>
                <p>
                    During a stay, you will have the opportunity to meet new people, discover new activities and develop your hobbies.
                </p>
                <p>
                    Daily activities are planned, in consultation with the carer, to keep our guests stimulated and entertained.
                </p>
                <p>
                    These may include walks to the park, attending local events, eating out and gardening.
                </p>


                <h4>
                    Who can access Respite / Short Term Accommodation?
                </h4>
                <p>
                    Any person who has NDIS funding and can be supported in a group environment can access our respite services.
                </p>

                <p>
                    It is available to children from seven to 17-years-old and adults from 18-years-old.
                </p>
                <p>
                    To find out more about our Respite Care services, contact Nava Care.
                </p>

                <p>
                    Contact Nava Care for your no obligation consultation regarding the best solution for your needs and goals.
                </p>

            </div>
            <Footer />
        </div>
    )
}

export default ShortTermAccomodation