import React from 'react'
import ServicePageServiceGrid from '../Components/ServicePageServiceGrid'
import Footer from '../Components/Footer'
import CommonHeader from '../Components/CommonHeader'

function Services() {
    return (
        <div>
            <div>
                <CommonHeader />
            </div>
            <div>
                <ServicePageServiceGrid />
            </div>
            <Footer />
        </div>
    )
}

export default Services