import React from 'react'
import CommonHeader from '../Components/CommonHeader'
import Footer from '../Components/Footer'
import image from '../images/children-with-down-syndrome-drawing-together.jpg'
import ScrollAnimation from 'react-animate-on-scroll'

function DailyPersonal() {
    return (
        <div>
            <CommonHeader />
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className='services-page-header-container'>
                    <div className='services-page-header-inner-container'>
                        <h1 className='text-center'>Daily Personal Activities (0107)</h1>
                        <div className='dummy-line mb-4 w-100' />
                        {/* <h4 className='text-center'>Connecting you is our priority - Reach out to us today!</h4> */}
                    </div>
                </div>
            </ScrollAnimation>
            <div className='divider'></div>
            {/* <h3 className='text-center'> Daily Personal Activities (0107)</h3> */}
            <div className='container'>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <div className='who-are-we-container p-4 gap-4'>
                        <div>
                            <img src={image} className='serviceInnerImage' alt='' />
                        </div>
                        <div>
                            <p>
                                The Nava Care give importance of daily personal activities in promoting independence and well-being for individuals with disabilities. We provides support through Assistance with Daily Living, ensuring that participants can carry out their personal tasks and routines with confidence and assistance when needed. These activities encompass a wide range of essential aspects, such as personal hygiene, grooming, dressing, meal preparation, eating, mobility, and medication management. By offering personalized support, the NDIS empowers individuals to maintain their dignity and autonomy while engaging in their daily routines. This assistance not only enhances their quality of life but also fosters a sense of self-worth and inclusion within the community. Through the provision of Nava care daily personal activities, individuals with disabilities are empowered to lead fulfilling lives and actively participate in society.
                            </p>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
            <Footer />
        </div>
    )
}

export default DailyPersonal