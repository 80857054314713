import React from 'react'
import imageone from '../images/social-part-banner-320x320.jpg.webp'
import imagetwo from '../images/Secondary-packaging-banner-image-320x320.jpg.webp'
import imagethree from '../images/Website-banner-12-adult-320x320.jpg.webp'
import imagefour from '../images/hm-serv-01.jpg'
import "animate.css/animate.compat.css"
import ScrollAnimation from 'react-animate-on-scroll'
import backgroundImage from '../images/aboutusimg.jpeg'


function AboutusSmall() {
    return (
        <div className='container abtusContainer  '>
            <img src={backgroundImage} alt='' className='abts-su-content' />
            {/* <div className='about-grid'>
                <ScrollAnimation animateIn="pulse">
                    <img src={imageone} alt='' style={{ borderRadius: '40px 0 0 0 ' }} />
                </ScrollAnimation>
                <ScrollAnimation animateIn="pulse">
                    <img src={imagetwo} alt='' style={{ borderRadius: '0  40px 0 0' }} />
                </ScrollAnimation>
                <ScrollAnimation animateIn="pulse">
                    <img src={imagethree} alt='' style={{ borderRadius: '0 0 0 40px' }} />
                </ScrollAnimation>
                <ScrollAnimation animateIn="pulse">
                    <img src={imagefour} alt='' style={{ borderRadius: '0 0 40px 0' }} />
                </ScrollAnimation>
            </div> */}
            <div className='abts-su-content'>
                <h3 className='w-100'>
                    About Nava Care
                </h3>
                <p className='w-100'>
                    It is obvious that no two participants need the exact same services because each individual has different needs in their daily lives and different obstacles associated with living with a disability.
                </p>
                <p className='w-100'>
                    At Nava Care, we create your care plan alongside you rather than on your behalf. We provide an unmatched amount of control over your plan, whether you need NDIS services for yourself or a loved one. Are you prepared to reclaim your life and your care?
                </p>
            </div>
        </div>
    )
}

export default AboutusSmall