import React from 'react'
import headerimage from '../images/header-img.jpg'
import Topbar from './Topbar'

function HomeHeader() {
    return (
        <div>
            <div className='header-main-container' >
                {/* <Topbar /> */}
                {/* <img
                    src={headerimage}
                    alt=''
                    style={
                        {
                            width: "99vw",
                            height: '100vh',
                            objectFit: 'cover'
                        }} /> */}
                <div className='header-text-container'>
                    <div className='header-welcome-title '
                    >Your journey our support</div>
                    <div className='header-welcome-heading'>Welcome to Nava Care</div>
                    <div className='dummy-line mb-4' />
                    <p style={{display: 'contents'}}>
                        With Nava Care experience exceptional care in a warm environment and trusted place for quality service that meets your specific needs.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default HomeHeader