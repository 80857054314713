import React from 'react'
import CommonHeader from '../Components/CommonHeader'
import awdl from '../images/adl-img.jpg'
import Footer from '../Components/Footer'
import image from '../images/children-with-down-syndrome-drawing-together.jpg'
import ScrollAnimation from 'react-animate-on-scroll'


function AssistanceWithDailyLiving() {
    return (
        <div>
            <CommonHeader />
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className='services-page-header-container'>
                    <div className='services-page-header-inner-container'>
                        <h1 className='text-center'>Daily Living Life Skills (0117)</h1>
                        <div className='dummy-line mb-4 w-100' />
                        {/* <h4 className='text-center'>Connecting you is our priority - Reach out to us today!</h4> */}
                    </div>
                </div>
            </ScrollAnimation>
            <div className='divider'></div>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className='container'>
                    <div className='who-are-we-container p-4 gap-4'>
                        <div>
                            <img src={image} className='serviceInnerImage' alt='' />
                        </div>
                        <div>
                            <p>
                                One of the primary goals of the Nava Care was to enable individuals who need reasonable and essential assistance to actively engage in their everyday activities and, consequently, promote their involvement in the wider community. An avenue through which this objective is achieved is Assistance with Daily Living, which offers support for routine personal tasks and includes supervision as needed. For instance, if you have a passion for tennis, attending craft or cooking lessons, or even singing, there is no reason to relinquish these enjoyable aspects of life.
                            </p>
                        </div>
                    </div>
                </div>
            </ScrollAnimation>
            <Footer />
        </div>
    )
}

export default AssistanceWithDailyLiving