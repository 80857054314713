import React from 'react'
import whoAreWeImage from '../images/1.jpg'
import CommonHeader from '../Components/CommonHeader'
import Footer from '../Components/Footer'
import "animate.css/animate.compat.css"
import ScrollAnimation from 'react-animate-on-scroll'
import visionImage from '../images/visionicon.png'
import missionImage from '../images/mission.png'

function AboutUs() {

    const whoAreWeStyle = {
        width: '100%'
    }

    const visMisImages = {
        height: '200px'
    }


    return (
        <>
            <CommonHeader />
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className='services-page-header-container'>
                    <div className='services-page-header-inner-container'>
                        <h1 className='text-center'>About us</h1>
                        <div className='dummy-line mb-4 w-100' />
                        {/* <h4 className='text-center'>Life Underlined</h4> */}
                    </div>
                </div>
            </ScrollAnimation>
            <div className='divider'>
                <ScrollAnimation animateIn="fadeIn" animateOnce={false}>
                    <div className='container'>
                        <div className='who-are-we-container p-4'>
                            <div className='pe-4'>
                                <div className='d-flex gap-2'>
                                    <h4
                                        style={{ fontWeight: "bold" }}
                                        className='mb-5'>Life </h4>
                                    <u><h4 style={{ fontWeight: "bold" }} className='mb-5'>Underlined</h4></u>
                                </div>
                                <p></p>
                                <p>
                                    It is obvious that no two participants need the exact same services because each individual has different needs in their daily lives and different obstacles associated with living with a disability.
                                </p><p>
                                    At Nava Care, we create your care plan alongside you rather than on your behalf. We provide an unmatched amount of control over your plan, whether you need NDIS services for yourself or a loved one. Are you prepared to reclaim your life and your care?
                                </p>

                            </div>
                            <div>
                                <img src={whoAreWeImage} alt='' style={whoAreWeStyle} />
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            </div >
            <div className='divider'></div>
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                <div className='container visionMissionContainer'>
                    <div className='text-center'>
                        <img src={visionImage} alt='' style={visMisImages} />
                        <div>Vision</div>
                        <p>Growing from disability to difference is a transformative journey that encompasses both personal and societal change. It is a path where meaningful coincidences play a significant role in shaping perspectives and fostering inclusivity. These synchronistic encounters connect individuals, inspiring empathy, understanding, and appreciation for diverse abilities. Through these encounters, barriers are shattered, prejudices are challenged, and a profound sense of unity emerges. Together, we forge a world that celebrates the beauty of differences, paving the way for a more inclusive and compassionate society.</p>
                    </div>
                    <div className='text-center'>
                        <img src={missionImage} alt='' style={visMisImages} />
                        <div>Mission</div>
                        <p>Creating Space for Energized Communities, also known as Living Free, is a transformative initiative dedicated to empowering mentally and physically challenged individuals through a culture of caring and sharing. By fostering an inclusive environment, we enable these individuals to thrive, breaking down barriers and nurturing their unique abilities. Through collaborative efforts and compassionate support, we establish safe spaces where everyone feels valued, heard, and respected. Together, we build a vibrant community that ignites energy, ignites passion, and celebrates the indomitable human spirit.</p>
                    </div>
                </div>
            </ScrollAnimation>
            <div className='divider'></div>
            <Footer />
        </>
    )
}

export default AboutUs