import React from 'react'
import Footer from '../Components/Footer'
import { Button, TextField } from '@mui/material'
import CommonHeader from '../Components/CommonHeader'
import BookAConsultation from '../Components/BookAConsultation'
import { IoLocation, IoCallSharp } from 'react-icons/io5'
import { AiFillClockCircle } from 'react-icons/ai'
import ScrollAnimation from 'react-animate-on-scroll'

function ContactUs() {
    return (
        <>
            <CommonHeader />
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className='services-page-header-container'>
                    <div className='services-page-header-inner-container'>
                        <h1 className='text-center'>Contact Us</h1>
                        <div className='dummy-line mb-4 w-100' />
                        <h4 className='text-center'>Connecting you is our priority</h4>
                    </div>
                </div>
            </ScrollAnimation>
            <div className='container p-4'>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <div className='contactContainerCard w-100'>
                        <div className='d-flex flex-column w-100 gap-4 mt-0 mb-4'>
                            <div className='contact-cards'>
                                <div ><IoLocation style={{ fontSize: '30px', color: '#0065bb' }} /></div>
                                <div>
                                    <strong>LOCATION</strong>
                                    <p />
                                    <p>Across NSW and Tasmania</p>
                                </div>
                            </div>
                            <div className='contact-cards'>
                                <div ><AiFillClockCircle style={{ fontSize: '30px', color: '#0065bb' }} /></div>
                                <div>
                                    <strong>Opening Hours</strong>
                                    <p />
                                    <div>WEEKDAYS : 9:00 AM - 5:00 PM</div>
                                    <div>WEEKENDS : Closed</div>
                                </div>
                            </div>
                            {/* <div className='contact-cards'>
                            <div ><IoCallSharp style={{ fontSize: '30px', color: '#0065bb' }} /></div>
                            <div>
                                <strong>CONTACT</strong>
                                <p />
                                <div>Address : #123, Sample Road, Sample Area, Location , 1123245</div>
                                <div>Phone : +61-403-953-209, +61-403-870-358</div>
                                <div>Email : info@navacare.com.au</div>
                            </div>
                        </div> */}
                        </div>
                        <div className='w-100'>
                            <form className='d-flex flex-column gap-4'>
                                <TextField
                                    size='small'
                                    label="Contact No"
                                    required />
                                <TextField
                                    size='small'
                                    type='email'
                                    label="Email"
                                    required />
                                <TextField
                                    multiline
                                    rows={6}
                                />
                                <Button
                                    size='large'
                                    type='submit'
                                    variant='contained'
                                    required>Submit</Button>
                            </form >
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
            <div className='divider' />
            <div>
                {/* <BookAConsultation /> */}
            </div>
            <div className='divider' />
            <Footer />
        </>
    )
}

export default ContactUs