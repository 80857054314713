import React from 'react'
import { AiFillFacebook, AiFillTwitterSquare, AiFillLinkedin, AiFillInstagram } from 'react-icons/ai'
import logo from '../images/logo white.png'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'

function Footer() {
    const footerStyle = {
        background: '#1c2640',
        color: 'white',
        padding: '20px'
    }
    const liStyle = {
        padding: '3px'
    }
    return (
        <div style={footerStyle}>
            <div className='container footer-ssss'>
                <div className='d-flex flex-column'>
                    <div>
                        <img src={logo} alt='' style={{ height: '40px' }} />
                    </div>
                    <div className='d-flex gap-4 mt-4'>
                        <AiFillFacebook style={{ fontSize: "30px" }} />
                        <AiFillTwitterSquare style={{ fontSize: "30px" }} />
                        <AiFillLinkedin style={{ fontSize: "30px" }} />
                        <AiFillInstagram style={{ fontSize: "30px" }} />
                    </div>
                </div>
                <div className='d-flex flex-column'>
                    <h5> Quick links</h5>
                    <ul style={{
                        listStyle: 'none',
                        padding: '0'
                    }} className='mt-2'>
                        <li style={liStyle}><Link to='/'>Home</Link></li>
                        <li style={liStyle}><Link to='/Career'>Career</Link></li>
                        <li style={liStyle}><Link to='/About_Us'>About us</Link></li>
                        <li style={liStyle}><Link to='/Contact_us'>Contact us</Link></li>
                    </ul>
                </div>
                <div className='d-flex flex-column gap-2'>
                    <div className='footer-contact-container'>
                        <div>Call  </div>
                        <div>
                            <div> +61-403-953-209,  </div>
                            <div> +61-403-870-358 </div>
                        </div>
                        <div> email</div>
                        <div>
                            <div> info@navacare.com.au,</div>
                            <div> enquiry@navacare.com.au</div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <Link to={"/Contact_us"} className='w-100'>
                            <Button fullWidth variant='contained' color='info'>Contact Us</Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div
                className='d-flex justify-content-between p-3 mt-3'
                style={{
                    fontSize: '12px',
                    borderTop: '1px dashed #e0e0e0'
                }}>
                <small>
                    Copyright 2023. All rights reserved.
                </small>
                <small >
                    Powered by <a href='https://ahydratech.com/' style={{ fontSize: '12px' }}>Ahydra&reg;</a>
                </small>
            </div>
        </div >
    )
}

export default Footer