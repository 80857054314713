import React from 'react'

function PageLoader() {
    return (
        <div style={{
            position: "absolute",
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            background:"transperant"
        }}>
            <div class="loader"></div>
        </div>
    )
}

export default PageLoader